/* eslint-disable react/no-unescaped-entities */
import PageNav from "./components/PageNav";
import { useState, useEffect } from "react";
import { UseUserAuth } from "./context/UseAuthContext";
import { CheckCircleIcon, RocketLaunchIcon } from "@heroicons/react/20/solid";
import AlertCredit from "./AlertCredit";
import Modal from "./Modal";
import InputConfirmation from "./UserInputDialogueBox";
import MissingInput from "./MissingInputDialogueBox";
import { Hourglass } from 'react-loader-spinner';
//import { useNavigate } from 'react-router-dom';
import ImageDisplay from "./ImageDisplay";
import app from "./context/Firebase";
import { getAnalytics, logEvent } from "firebase/analytics";

function CreateEssential() {
  const [bookId, setBookId] = useState(null); // Define userQueryID state
  const [story, setStory] = useState("");
  const [legends, setLegends] = useState("");
  const [paragraphs, setParagraphs] = useState("");
  const [storyTitle, setStoryTitle] = useState("");
  const [prompts, setPrompts] = useState("");
  const [lora, setLora] = useState("");

    return (
      <div>
        <PageNav className="absolute inset-x-0 top-0 z-50" />
        <Heading />
        <Generate />
        <MyForm
          setBookId={setBookId}
          setStory={setStory}
          setLegends={setLegends}
          setParagraphs={setParagraphs}
          setStoryTitle={setStoryTitle}
          setPrompts={setPrompts}
          setLora={setLora}
        />
        {bookId &&
          story &&
          legends &&
          paragraphs &&
          storyTitle &&
          prompts &&
          lora && (
            <ImageDisplay
              bookId={bookId}
              legends={legends}
              paragraphs={paragraphs}
              storyTitle={storyTitle}
              prompts={prompts}
              lora={lora}
            />
          )}
      </div>
    );
}
function Heading() {
  const { user } = UseUserAuth();
  const [userName, setUserName] = useState("");
 // console.log("create user", user);

 const fetchUserName = async () => {
  try {
    const idToken = await user.getIdToken();
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/user/getUserName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user name");
    }

    const data = await response.json();
    setUserName(data);
  } catch (error) {
    console.error("Error fetching user name:", error);
  }
};

useEffect(() => {
  if (user) {
    fetchUserName();
  }
}, [user]);

return (
  <div className="flex justify-center text-lg font-semibold p-4 box mt-2 text-center text-indigo-600">
    Welcome <br />
    {userName || (user ? (user.displayName ? user.displayName : user.email) : "Guest")}
  </div>
);
}

function Generate() {
  return (
    <div className=" flex  bg-white px-4 py-2 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-center mb-4">
          Instructions:
        </h1>{" "}
   {/*     <p className="mt-6 text-xl leading-8">
          Follow these simple steps to create your very own one-of-a-kind
          storybook
        </p> */}
        <div className="mt-8 max-w-2xl">
          <ul
            role="list"
            className="mt-8 max-w-2xl space-y-4 text-gray-600 text-justify"
          >
            <li className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  Fill Details:{" "}
                </strong>
                Enter character name. Select gender and reader's age group. Describe your story's theme or pick a moral value or you can do both to shape your storyline.
              </span>
            </li>

            <li className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                Generate Story:{" "}
                </strong>{" "}
                Click submit. Story creation takes about 2 minutes. {/*You will then head to the Library page to read your book online or download it.*/}
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                Review Images:{" "}
                </strong>{" "}
                Not happy with a picture? Use "redraw image" button (up to 3 times per book). Select your preferred version.
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                Finalize Book:{" "}
                </strong>{" "}
                Click "Build My Book" to create PDF and online versions. Access from Library page.{" "}
              </span>
            </li>

            
          </ul>
        </div>
      </div>
    </div>
  );
}

function MyForm({
  setBookId,
  setStory,
  setLegends,
  setParagraphs,
  setStoryTitle,
  setPrompts,
  setLora,
}) {
  const [gender, setGender] = useState("girl");
  const [readerAgeGroup, setReaderAgeGroup] = useState('2-4years')
  const [mainChrName, setMainChrName] = useState("");
  const [description, setDescription] = useState("");
  const [moralValue, setMoralValue] = useState("");
  const [appearanceValue, setAppearanceValue] = useState("medium_brown");
  const [selectedStoryType, setSelectedStoryType] = useState('Essential');
  
  const { user } = UseUserAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false); // Set to true initially to show the modal
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  // const [creditPoints, setCreditPoints] = useState(0); // Initialize creditPoints state
  const [isMissingInputOpen, setIsMissingInputOpen] = useState(false);

  async function pollBackendForResult(bookId) {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/textOutput`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
          body: JSON.stringify({ bookId: bookId }),
        }
      );
      if (response.status === 404) {
        console.warn("Endpoint not ready yet. Retrying in 30 second...");
        setTimeout(() => pollBackendForResult(bookId), 30000);
        return;
      }

      const jsonString = await response.text();
      //console.log("resp text", response)
      //console.log("resp textOut", jsonString)
      const resultReady = jsonString.includes(bookId);
      const data = JSON.parse(jsonString);
      const userStory = data.story;
      const unclean_illustration = data.updatedIllustration;
      const prompts = data.promptList;
      const lora = data.loraList;
      //Extracting all paragraphs of a story
      const allParagraphs = userStory
        .split("\n")
        .filter((paragraph) => paragraph.trim() !== "");
      const unclean_paragraphs = allParagraphs.slice(1);

      // Remove numbers from paragraphs
      const paragraphs = unclean_paragraphs.map((paragraph) => {
        const cleanedText = paragraph.replace(/^\d+\.\s*/, "");
        return cleanedText;
      });

      // Remove numbers from legends
      const illustration = unclean_illustration.map((paragraph) => {
        // Split the paragraph by period (.)
        const splitParagraph = paragraph.split(/\. /);
        // Take the second part of the split as the desired text
        const cleanedText = splitParagraph.slice(1).join(". ");
        return cleanedText;
      });

      //Extracting title
      //const titleMatch = userStory.match(/^Title: (.+)$/m);
      //const storyTitle = titleMatch ? titleMatch[1] : "";

          // Determine the format of the story
      const isOldFormat = userStory.startsWith('Title:');

      let storyTitle = '';
      //let splits = story.split('\n').filter(paragraph => paragraph.trim() !== '');

      if (isOldFormat) {
        // Extract the title using the old format
        const titleMatch = userStory.match(/^Title: (.+)$/m);
        storyTitle = titleMatch ? titleMatch[1] : '';
        // Remove the first line (title) from the paragraphs
        //splits = splits.slice(1);
      } else {
        // Extract the title from the first line for the new format
        const splits = userStory.split('\n').filter((paragraph) => paragraph.trim() !== '');
        storyTitle = splits[0].trim();
        if (storyTitle.startsWith('**') && storyTitle.endsWith('**')) {
          storyTitle = storyTitle.replace(/\*\*/g, '').trim();
        }
        // Remove the title from the paragraphs
        //splits = splits.slice(1);
        // Remove non-alphanumeric characters from the start and end of the title
      storyTitle = storyTitle.replace(/^[^\w]+|[^\w]+$/g, '');
      }

      if (resultReady) {
        // Result is ready, fetch the result
      
        setParagraphs(paragraphs);
        setStoryTitle(storyTitle);
        setStory(userStory);
        setLegends(illustration);
        setIsLoading(false);
        setPrompts(prompts);
        setLora(lora);

       
        //at this stage put story title, para, prompt, lora, legends to the db user_output_query
        //this db should have userID, bookID, and the details mentioned above
      } else {
        // Continue polling every 30 seconds
        setTimeout(() => pollBackendForResult(bookId), 30000); // Poll every 30 seconds
      }
    } catch (error) {
      console.error("Error polling for result:", error);
      setTimeout(() => pollBackendForResult(bookId), 30000); // Poll every 30 seconds
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    let credit_points = 0;

    try {
      const idToken = await user.getIdToken();
      //console.log("credit-path", `${process.env.REACT_APP_BACKEND}/user/getNumCredits`)
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/getNumCredits`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
          body: JSON.stringify(),
        }
      );
      if (!response.ok) {
        console.warn("Something wrong");
        return 0;
      }
      const result = await response.json();
      credit_points = parseInt(result);
      
    } catch (error) {
      console.error("Error", error);
      return 0;
    }
    if (credit_points < 1 || isNaN(credit_points)) {
      setAlertVisible(true);
      // maybe put return here *******************
      return;
    }
    //first make a request to backend checking the number of creadits is >=1 book,
    //if there are credits then proceed as is, otherwise throw error stating that
    //please buy credit first
    setIsConfirmationOpen(true);
    const analytics = getAnalytics(app);
      logEvent(analytics, "working_lead");
      console.log("working_lead_essential")
    //setCreditPoints(credit_points); // Update creditPoints state
  }
  async function handleConfirm() {
    // e.preventDefault();
    const idToken = await user.getIdToken();
    // Update creditPoints state after confirming

    setStory("");
    setLegends("");
    setParagraphs("");
    setStoryTitle("");
    setPrompts("");
    setLora("");
    const storyType = 'Essential'
    const info = {
      gender,
      mainChrName,
      description,
      moralValue,
      appearanceValue,
      readerAgeGroup,
      storyType

    };
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: idToken },
      body: JSON.stringify(info),
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/create`,
        requestOptions
      );
      if (!response.ok) {
        setIsMissingInputOpen(true);
        setIsLoading(false);
      //  console.log("isloading", isLoading)
        return;
      }
      setIsMissingInputOpen(false);
      
      const { bookId } = await response.json();
      setBookId(bookId);
      pollBackendForResult(bookId);
      setIsConfirmationOpen(false); // Moved inside try block
    } catch (error) {
      console.error("Error handling confirmation:", error);
      setIsLoading(false);
    }
  }

  function handleCancel(e) {
    e.preventDefault();
    // Handle cancellation if needed
    console.log("Creation canceled.");

    // Close the confirmation dialog
    setIsConfirmationOpen(false);
  }

  return (
    <div className="flex justify-center bg-slate-50">
      <div className="max-w-md mb-10">
        <h2 className="flex gap-x-3 mt-8 text-2xl font-bold tracking-tight text-gray-900 text-center">
          <RocketLaunchIcon
            className="mt-1 h-5 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          <span>
            {" "}
            Ready to unleash your creativity? Fill and submit the form below.
          </span>
        </h2>
        <div className="grid justify-center bg-slate-50">
          <form onSubmit={handleSubmit}>
            <div className="space-y-5">
              <div className="border-b border-gray-900/10 pb-2"> </div>

              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Provide your input to build a story
              </h2>
              <h2 className="text-base text-md leading-7 text-gray-700">
                Story Type Selected : Essential 
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Once submitted, scroll down to see your story come to life.
              </p>

              <div className=" mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Name of the main character
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={mainChrName}
                      onChange={(e) => setMainChrName(e.target.value)}
                      style={{ padding: "10px" }}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="gender"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Gender
                  </label>
                  <div className="mt-2">
                    <select
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      id="gender"
                      name="gender"
                      autoComplete="gender"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option value="girl">Girl</option>
                      <option value="boy">Boy</option>
                    </select>
                  </div>
                </div>

               

                <div className="sm:col-span-3 ">
                  {" "}
                  <label
                    htmlFor="moral"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Moral of the story
                  </label>
                  <div className="mt-2">
                    <select
                      value={moralValue}
                      onChange={(e) => setMoralValue(e.target.value)}
                      id="moral"
                      name="moral"
                      autoComplete="moral"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option value=""></option>
                      <option value="courage">Courage</option>
                      <option value="honesty"> Honesty</option>
                      <option value="respect">Respect</option>
                      <option value="responsibility">Responsibility</option>
                      <option value="empathy">Empathy</option>
                      <option value="compassion">Compassion</option>
                      <option value="fairness">Fairness</option>
                      <option value="gratitude">Gratitude</option>
                      <option value="cooperation">Cooperation</option>
                      <option value="generosity">Generosity</option>
                      <option value="self-discipline">Self-Discipline</option>
                      <option value="perseverance">Perseverance</option>
                      <option value="humility">Humility</option>
                      <option value="tolerance">Tolerance</option>
                      <option value="patience">Patience</option>
                      <option value="kindness">Kindness</option>
                      <option value="resilience">Resilience</option>
                      <option value="forgiveness">Forgiveness</option>
                      <option value="integrity">Integrity</option>
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="appearance"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Customize your character's appearance
                  </label>
                  <div className="mt-2">
                    <select
                      value={appearanceValue}
                      onChange={(e) => setAppearanceValue(e.target.value)}
                      id="appearance"
                      name="appearance"
                      autoComplete="appearance"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      {" "}
                      <option value="fair_blond">
                        Fair Skin Tone, Blond Hair
                      </option>
                      <option value="medium_brown">
                        Medium Skin Tone, Brown Hair
                      </option>
                      <option value="dark_black">
                        Dark Skin Tone, Black Hair
                      </option>
                      <option value="warm_black">
                        Warm, Light Tan Skin Tone, Black Hair
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Description
                  </label>
                  <div className="mt-2">
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      id="description"
                      name="description"
                      rows={3}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      style={{ padding: "10px" }}
                      placeholder="Tip: Include detailed storylines, actions and events with multiple characters to create a rich, engaging story!"

                    />
                  </div>
                  <p className="mt-3 text-sm leading-6 text-gray-600">
                    Write a few sentences about storyline.
                  </p>
                </div>
              </div>
           

            <div className="sm:col-span-6">
                  <label
                    htmlFor="readerAgeGroup"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Reader's age group
                  </label>
                  <div className="mt-2">
                    <select
                      value={readerAgeGroup}
                      onChange={(e) => setReaderAgeGroup(e.target.value)}
                      id="readerAgeGroup"
                      name="readerAgeGroup"
                      autoComplete="readerAgeGroup"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option value="2-4years">2-4 years</option>
                      <option value="5-7years">5-7 years</option>
                    </select>
                  </div>
                </div>
                
                <div className="border-b border-gray-900/10 pb-2">
                <div className="mt-2 space-y-2"></div>
              </div>
            </div>
            <div className="mt-2 flex items-center justify-end gap-x-6">
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Generate
              </button>
            </div>
    
          </form>

             {isLoading && (
            <div className="fixed inset-0 flex flex-col items-center justify-center bg-opacity-50 bg-gray-800 z-50">            <Hourglass
              visible={true}
              height="80"
              width="80"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={['#306cce', '#72a1ed']}
            />
                <p className="mt-4 text-white text-lg text-center">Your storybook is being generated and typically takes around 2 minutes. Thank you for your patience!</p>
          </div>
        )}

        </div>
      </div>
      <Modal isOpen={alertVisible} onClose={() => setAlertVisible(false)}>
        <AlertCredit />
      </Modal>
      {/* Show the confirmation dialog if it's open */}
      {isConfirmationOpen && (
        <InputConfirmation
          onConfirm={(e) => {handleConfirm(e); setIsConfirmationOpen(false)}}
          onCancel={(e) => {handleCancel(e);  setIsConfirmationOpen(false)}}
        />
      )}
      {isMissingInputOpen && (
        <MissingInput
          onConfirm={() => setIsMissingInputOpen(false)} // Just close the dialog on confirm
        />
      )}
    </div>
  );
}

export default CreateEssential;