import PageNav from "./components/PageNav";
import app from "./context/Firebase";
import { useEffect } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

export default function AboutUs() {
  useEffect(() => {
    console.log("log about page");
    const analytics = getAnalytics(app);
    logEvent(analytics, "about_page_visited");
  }, []);

    return (
      <> 
    <PageNav/>
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-4">
            <div className="relative overflow-hidden rounded-3xl bg-gray-900">
              <img
                className="h-full w-full object-cover"
                src="https://static.mytalemaker.com/mom-son10_cropped.png"
                alt="mom and son reading together"
              />
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
              <h1 className="mt-0 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Story Behind MyTaleMaker
              </h1>
              <div className="max-w-xl">
                <p className="mt-4">
                Hi there! I'm Bhumika, creator of MyTaleMaker. Over the past decade, I've journeyed through many roles. But my favorite role by far has been being a mom to a wonderful little boy.</p>
                <p className="mt-8">
                Growing up, my parents filled our lives with imaginative stories—my dad's tales were filled with science fiction, while my mom's stories were all about kids and their emotions. My brother and I adored those stories, and they still hold a special place in our hearts. Those storytelling moments were magical and deeply personal.
                </p>
                <p className="mt-8">
                When I became a mom, I wanted to share that same magic of joy, adventure, and learning with my son. But I dreamed of more than just a few stories—I wanted a whole world of diverse tales. And that's how MyTaleMaker was born.
                </p>
              </div>

              <p className="mt-8">
              When my son was just 2, our stories were simple bedtime fun. Now, at 3, the stories have grown with him, helping me navigate the challenges of parenting. MyTaleMaker has become my go-to tool for teaching him valuable life lessons—like sharing with friends, not biting them during play, and using his words instead. These personalized stories feature characters and events from his own life, making the lessons more relatable and impactful. Plus, they create wonderful family moments filled with laughter and learning.
                </p>

                <p className="mt-8">
                While MyTaleMaker offers personalized storybooks, it stands out as a powerful tool for teaching important life lessons, going beyond the typical customization to create meaningful and impactful experiences. I'm thrilled to share this with other moms, dads, uncles, aunts, and grandparents. I hope you enjoy it as much as we do!                </p>
            </div>   
         
                  
          </div>         
           
          </div>
        </div>
      </div>
   
    </>
  )}