import PageNav from "./components/PageNav";
import { useNavigate } from 'react-router-dom';
import sampleData from './assets/data.json'
import { generateSlug } from './utils';

const viewgallery = [{id : 0, title : "Harper and the Magic of Sharing",storyType: "Enhanced",theme : "sharing", moral: "", description : "Harper learns sharing is caring. She initially did not like to share her toys with her brother Oliver. Her mom and dad teach her to share and talk about the benefits of sharing -  such as it brings happiness and joy to everyone,  sharing creates more fun and strengthens friendships.", readerAgeGroup : "5-7 years old",thumbNail : "https://static.mytalemaker.com/HarperAndTheMagicOfSharing_1.jpg" },
    {id : 1, title : "Mei Learns About Emotions",storyType: "Enhanced",theme : "emotions", moral: "", description : "Mei learns about emotions. Her mom and dad teach her about them. Her little brother Feng also participates. Mei tells about them to her friends John and Lily.", readerAgeGroup : "2-4 years old",thumbNail : "https://static.mytalemaker.com/EmilyLearnsAboutEmotions_1.jpg" },
    {id : 2, title : "Daniel and the Dark",storyType: "Enhanced",theme : "fear", moral: "courage", description : "Daniel learns to overcome his fear of dark. His mom and dad help him. His teacher at preschool, Ms. Lily helps. His sister Nina supports him. ", readerAgeGroup : "2-4 years old",thumbNail : "https://static.mytalemaker.com/DanielAndTheDark_1.jpg" },
    {id : 3, title : "Mateo Learns to Use His Words", storyType: "", theme : "learns not to bite friends", moral: "", description : "Mateo used to bite his friends. He has bitten Ethan and Emma. At daycare, he learns not to bite friends. His mom tells him to use words to express his feelings. His teacher, Miss Jenny, also teaches him that biting is for food and snacks, not for friends.", readerAgeGroup : "2-4 years old",thumbNail : "https://static.mytalemaker.com/MateoLearnsToUseHisWords_1" },
                   {id: 4, title :"Nora and the Magic of Emotions", storyType: "", theme : "emotions", moral:"", description: "Nora learns about emotions. Her mother teaches her what they are and how to express them.",readerAgeGroup : "2-4 years old", thumbNail :"https://static.mytalemaker.com/NoraAndTheMagicOfEmotions_1"},
                   {id: 5, title :"Ian's Adventure in Paris",storyType: "", theme : "responsibility", moral:"Responsibility", description:"Ian goes on a trip to Paris, France, with his parents and little brother.",readerAgeGroup : "5-7 years old", thumbNail :"https://static.mytalemaker.com/IanAdventureInParis_2"},
                   {id: 6, title :"Emily and the Magic of Kindness",storyType: "", theme : "kindness", moral:"Kindness", description:"",readerAgeGroup : "5-7 years old", thumbNail :"https://static.mytalemaker.com/EmilyAndTheMagicOfKindness_1"},
                 
            ]
export default function Gallery(){
    const navigate = useNavigate();

    function handleReadBook(e, id){
        try{
        const sampleBookData = sampleData.stories[id]
        if (sampleBookData) {
        const slug = generateSlug(sampleBookData.title);
        navigate(`/gallery/${slug}`, { state: { sampleBookData: sampleBookData } });}
        else{
            console.error('Error loading sample book');
        }}
        catch(error){
            console.error("Error in sending book", error)
        }
        
     
    }
    return (
        <div> 
        <PageNav className="absolute inset-x-0 top-0 z-50"/>
      
     <div className="bg-white">
         <div className="mx-auto max-w-3xl px-4 py-16 sm:px-6 sm:py-24">
             <div className="w-full"> {/*"max-w-xl" */}
                 <h1 id="your-orders-heading" className="text-3xl font-bold tracking-tight text-gray-900 text-justify">
                     Gallery
                 </h1>
                 <p className="mt-2 text-md text-gray-500 text-justify">
                 Explore our collection of free story samples! Each sample includes information on user input for the reader's age group, as well as moral value or description used for building a story.{/*Discover new additions every week! */}
                 </p>
             </div>

             <div className="mt-6 space-y-16 sm:mt-16">
                 {viewgallery.map((item, index) => (
                     <section key={item.id} aria-labelledby={`${item.id}-heading`}>
                         <div className="space-y-1 md:flex md:items-baseline md:space-x-4 md:space-y-0">
                         </div>
                         <div className="-mb-6 mt-6 flow-root divide-y divide-gray-200 border-t border-gray-200">
                             <div key={index} className="py-6 sm:flex">
                                 <div className="flex space-x-4 sm:min-w-0 sm:flex-1 sm:space-x-6 lg:space-x-8">
                                     <img src={item.thumbNail} className="h-20 w-20 flex-none rounded-md object-cover object-center sm:h-48 sm:w-48" alt=""/>
                                     <div className="min-w-0 flex-1 pt-1.5 sm:pt-0">
                                         <p className="mt-1 font-medium text-gray-900"> {item.title}</p>
                                         <p><span className="mt-1 text-sm text-gray-700" aria-hidden="true">StoryType : {item.storyType}</span>  </p>
                                         <p><span className="mt-1 text-sm text-gray-700" aria-hidden="true">Reader's Age Group : {item.readerAgeGroup}</span>  </p>
                                         <p><span className="mt-1 text-sm text-gray-700" aria-hidden="true">Moral : {item.moral}</span>  </p>
                                         <p><span className="mt-1 text-sm text-gray-700" aria-hidden="true">Description : {item.description}</span>  </p>
                                         
                                       
                                     </div>
                                 </div>
                                 <div className="mt-6 space-y-4 sm:ml-6 sm:mt-0 sm:w-40 sm:flex-none">
                                    
                                     <button
                                         type="button"
                                         onClick = {(e) => {handleReadBook(e,item.id)}}
                                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-full sm:flex-grow-0"
                                     >
                                         Read
                                     </button>
                                 </div>
                             </div>

                         </div>
                     </section>
                 ))}
             </div>
         </div>
     </div>
 
        </div>
    )
}
