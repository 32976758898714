import React from 'react';

export default function EducatorBenefits() {
  return (
    <div className="bg-white py-12 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          How MyTaleMaker Helps in the Classroom
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Personalized stories that solve real classroom challenges
          </p>
        </div>
        
        <div className="mx-auto mt-12 grid max-w-5xl grid-cols-1 gap-8 lg:grid-cols-2">
          <div className="rounded-2xl bg-indigo-50 p-8">
            <h3 className="text-xl font-semibold text-indigo-900 mb-6">Perfect for Daily Classroom Challenges:</h3>
            <ul className="space-y-4">
            <li className="flex items-start gap-x-3">
                <span className="text-indigo-600 text-xl mt-0.5">•</span>
                <span>Managing big emotions</span>
              </li>

              <li className="flex items-start gap-x-3">
                <span className="text-indigo-600 text-xl mt-0.5">•</span>
                <span>Stopping aggressive behaviors (biting, hitting, hair pulling)</span>
              </li>
              <li className="flex items-start gap-x-3">
                <span className="text-indigo-600 text-xl mt-0.5">•</span>
                <span>Cleanup and organizational skills</span>
              </li>
              <li className="flex items-start gap-x-3">
                <span className="text-indigo-600 text-xl mt-0.5">•</span>
                <span>Sharing and taking turns</span>
              </li>
              <li className="flex items-start gap-x-3">
                <span className="text-indigo-600 text-xl mt-0.5">•</span>
                <span>Following classroom routines</span>
              </li>
              <li className="flex items-start gap-x-3">
                <span className="text-indigo-600 text-xl mt-0.5">•</span>
                <span>Toilet training support</span>
              </li>
              <li className="flex items-start gap-x-3">
                <span className="text-indigo-600 text-xl mt-0.5">•</span>
                <span>and many more...</span>
              </li>
            </ul>
          </div>
          
          <div className="rounded-2xl bg-indigo-50 p-8">
            <h3 className="text-xl font-semibold text-indigo-900 mb-6">MyTaleMaker Benefits for Educators:</h3>
            <ul className="space-y-4">
            <li className="flex items-start gap-x-3">
                <span className="text-indigo-600 text-xl mt-0.5">•</span>
                <span>Tailor stories to meet each child's unique learning needs</span>
              </li>
              <li className="flex items-start gap-x-3">
                <span className="text-indigo-600 text-xl mt-0.5">•</span>
                <span>Address specific behavioral challenges with stories featuring your classroom situations</span>
              </li>
              <li className="flex items-start gap-x-3">
                <span className="text-indigo-600 text-xl mt-0.5">•</span>
                <span>Boost language skills (vocabulary, comprehension) with personalized content children love to read or listen to</span>
              </li>
              <li className="flex items-start gap-x-3">
                <span className="text-indigo-600 text-xl mt-0.5">•</span>
                <span>Generate illustrated stories in just 2 minutes—saving valuable prep time</span>
              </li>
              <li className="flex items-start gap-x-3">
                <span className="text-indigo-600 text-xl mt-0.5">•</span>
                <span>Flexible reading options with both digital access and downloadable PDFs</span>
              </li>
              <li className="flex items-start gap-x-3">
                <span className="text-indigo-600 text-xl mt-0.5">•</span>
                <span>Strengthen parent-teacher partnerships through shareable stories for home reinforcement</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}