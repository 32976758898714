import PageNav from "./components/PageNav";
import { NavLink } from "react-router-dom";
import { UseUserAuth } from './context/UseAuthContext';
import React, {  useEffect, useState } from "react";
import Pricing from './Pricing';
import Contact from "./Contact";
//import About from './About';
//import CreationInstructions from './CreationInstructions';
import Banner from './Banner'; 
import BannerFreebie from "./BannerFreebie";
import { Helmet } from 'react-helmet';
import { BoltIcon, BookOpenIcon, FaceSmileIcon, HandThumbUpIcon, StarIcon, UserCircleIcon, ChevronDownIcon,
  ChevronUpIcon  } from '@heroicons/react/20/solid'

function Homepage() {
  //const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const pricingRef = React.createRef();
  const [showBanner, setShowBanner] = useState(false);
  const [showFreebie, setShowFreebie] = useState(false);
  const structuredData =  {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "MyTaleMaker",
    "url": "https://www.mytalemaker.com",
    "description": "Create personalized stories for children in minutes using AI. Tailored to your child's imagination with beautiful illustrations."
  }
  useEffect(() => {
    async function fetchData(){
    try {

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/public/getCountry`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          },
        }
      );

      const data = await response.json();
      if (data.status === 403){
        setShowBanner(true);
        setShowFreebie(false);
      //  alert('We are sorry. Currently, the beta launch is not available in your country. We hope to see you very soon!')
      }
      else{ if(data.status === 200) {
        setShowBanner(false);
        setShowFreebie(true);
      }
      }
    } catch (error) {
      console.error("Error fetching country information:", error);
    }
  };
      // Call fetchData to fetch country information
  fetchData();
    // Handle URL parameters for scrolling
    const urlParams = new URLSearchParams(window.location.search);
    const section = urlParams.get("section");

    if (section === "pricing") {
      pricingRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      // Optional: Handle the case where no section parameter is provided
      // (e.g., do nothing, scroll to the top, etc.)
      // window.scrollTo(0, 0); // Scroll to the top of the page
    }
  }, []);
  return (
    <div className="bg-white">
      <Helmet>
        <title>MyTaleMaker</title>
        <meta property="og:site_name" content="MyTaleMaker" />
        <meta name="description" content="Create personalized stories for children in minutes using AI. Tailored to your child's imagination with beautiful illustrations." />
        <meta property="og:title" content="MyTaleMaker" />
        <meta property="og:description" content="Create personalized stories for children in minutes using AI. Tailored to your child's imagination with beautiful illustrations." />
        <meta name="application-name" content="MyTaleMaker" />
        <meta property="og:url" content="https://www.mytalemaker.com" />
        <meta property="og:image" content="https://static.mytalemaker.com/logo-icon.png" />
        <link rel="canonical" href="https://www.mytalemaker.com" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <PageNav classname= "absolute inset-x-0 top-0 z-50"/>  
      {showBanner && <Banner />} {/*onConfirm={() => setShowBanner(false)} */}
      {showFreebie && <BannerFreebie/>} 
      <Display />    
      
     {/* <CreationInstructions/> */}
      <div ref={pricingRef}> 
        <Pricing />
      </div> 
      <About/>
      <Faqs />
      <Contact/>
    </div>
  );
}

function Display() {
  const { user } = UseUserAuth();

  return (
    <main>
      <div className="bg-white">
        <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-4">
          <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
              <div className="lg:col-span-1">
                {/* Banner */}
                <div className="inline-flex items-center bg-indigo-600 text-white px-4 py-2 rounded-full text-sm font-medium">
                  ⭐ Create Personalized Children's Stories with AI
                </div> 

                <h1 className="mt-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
Write Your Child Into Their Own Adventure
</h1>
{/*<p className="mt-4 text-lg text-gray-600">
Share magical moments together with AI-powered personalized stories, ready to read in as little as 2 minutes. Perfect for fostering a love of reading, supporting social-emotional learning, building confidence, and crafting bedtime stories. Entertain, inspire, and create lasting family memories!                  </p> */}
                <p className="mt-4 text-lg text-gray-600">
                Engage your child with beautifully illustrated custom stories that spark creativity, promote social skills, and foster a love for reading. Entertain, inspire, and create lasting family memories!
                </p>

                {/* Trust Signals */}
                <div className="mt-6 space-y-3">
                  <div className="flex items-center gap-x-2">
                    <span className="text-indigo-600">✓</span>
                    <span>First personalized story completely free - No credit card needed</span>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <span className="text-indigo-600">✓</span>
                    <span>Create custom stories in as little as 2 minutes</span>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <span className="text-indigo-600">✓</span>
                    <span>Created with love by a mom for ages 2-7</span>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <span className="text-indigo-600">✓</span>
                    <span>10 beautiful AI illustrations per story</span>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <span className="text-indigo-600">✓</span>
                    <span>Download as PDF or read online instantly</span>
                  </div>
                </div>

                {/* CTAs */}
                <div className="mt-8 flex items-center gap-x-4">
                  <NavLink
                    to={user ? '/create' : "/login"}
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Start Your Free Story
                  </NavLink>
                  <NavLink 
                    to="/gallery" 
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    See Examples <span aria-hidden="true">→</span>
                  </NavLink>
                </div>
              </div>

                              {/* Happy Family Image */}
              <div className="mt-8 lg:mt-0">
              <img
                  //src="https://static.mytalemaker.com/family_mytalemaker.jpg" // You'll need to update this path
                  src="https://static.mytalemaker.com/family_mytalemaker2.webp"
                  alt="Happy family creating a personalized story together on MyTaleMaker"
                  className="w-full rounded-2xl object-cover shadow-xl"
                  width="1024"
                  height="1024"
                  loading="lazy"
                  fetchpriority="high"
              />
              </div>
            </div>

           
          </div>
          <div className="absolute inset-x-0 bottom-0 -z-10 h-16 bg-gradient-to-t from-white sm:h-24" />
        </div>
      </div>
    </main>
  );
}

// Features section (previously called About)
function About() {
  const features = [
    {
      name: "Tailored to Your Child's Imagination",
      description:
        "Crafted by our AI using your family's input, each unique short story come alive with 10 captivating paragraphs and 10 beautiful illustrations. Parents, kids, aunts, uncles, and grandparents can all contribute, making it a treasure your family will cherish forever.",
      icon: BoltIcon,
    },
    {
      name: "Nurture Good Habits and Moral Values",
      description:
        "Empower your child's moral compass with our personalized stories! Tailor-made for ages 2-7, you can customize the protagonist's name, gender, and even select a moral value or provide any description. Each story can foster valuable life lessons, depending on your input, making learning fun and deeply personal.",
      icon: StarIcon,
    },
    {
      name: "Create Unforgettable Memories",
      description:
        "Building these stories with your kids and reading them together creates special moments they will eagerly look forward to. Whether you create childhood stories, bedtime stories for kids or any other short stories for kids, these tales will resonate with your child, adding excitement and making your shared reading time memorable. This fosters a deeper connection and creates cherished moments that last a lifetime.",
      icon: FaceSmileIcon,
    },
    {
      name: "Story Editing & Image Refinement Tools",
      description: 
        "Our stories feature 10 beautifully illustrated paragraphs. With our Essential option, you get 3 AI-powered image revisions per book. For those seeking more customization, our Enhanced option allows you to not only edit story content but also offers up to 20 image revisions - either regenerating from the same prompt or creating entirely new images with your own custom prompts, ensuring every detail matches your vision perfectly.",
      icon: HandThumbUpIcon,
    },
    {
      name: "Foster love for reading",
      description:
        "Our personalised stories ignite an interest in reading, enhancing language skills while sparking curiosity and creativity.",
      icon: BookOpenIcon,
    },
    {
      name: "Craft Meaningful Alternatives to Generic Online Content",
      description:
        "Our Personalized stories offer a refreshing alternative to generic online videos, providing tailored content that engages and educates children in a meaningful way.",
      icon: UserCircleIcon,
    }
  ];

  return (
    <div className="bg-white py-12 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Discover the joy of personalized storytelling
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-700">
            Create personalized stories that inspire, delight, and ignite imagination – because every child deserves a tale as unique as they are!
          </p>
        </div>
        <div className="mx-auto mt-10 max-w-2xl sm:mt-10 lg:mt-12 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-8 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 p-6 border border-gray-100">
                <dt className="flex items-center gap-x-3 text-lg font-semibold leading-7 text-gray-900">
                  <div className="rounded-md bg-indigo-50 p-2">
                    <feature.icon className="h-6 w-6 flex-none text-indigo-600" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

// New FAQ section with dropdown functionality
function Faqs() {
  const [openFaq, setOpenFaq] = useState(null);

  const toggleFaq = (id) => {
    setOpenFaq(openFaq === id ? null : id);
  };

  const faqs = [
    {
      id: 1,
      question: "What is the purchasing process for these books?",
      answer:
        "Currently, we offer digital versions of our books. Upon purchase of the necessary credits, customers can build their books. Books will be accessible in your account, and you can either download them in PDF format for your convenience or read them online.",
    },
    {
      id: 2,
      question: "What sets your storybooks apart from others?",
      answer:
        "Unlike traditional books, our AI creates unique, personalized stories in minutes! Each book is a treasured keepsake with high-quality illustrations and text, all at a great price. Plus, we offer unmatched customization with generous AI-powered image revisions to ensure your complete satisfaction.",
    },
    {
      id: 3,
      question: "What options do you provide for customization and personalization in the storybooks?",
      answer:
        "Utilizing our intuitive platform, users can customize the protagonist's name, inject their creative touch into the narrative, or select moral values to enrich the tale. Additionally, our ongoing innovation introduces a diverse range of character appearances, ensuring each child discovers a representation that speaks to them. Upon logging in and visiting our creation page, users can explore detailed steps to personalize their storybooks.",
    },
    {
      id: 4,
      question: "What age range are these storybooks suitable for?",
      answer:
        "Our personalized storybooks are designed to enchant the hearts of young readers aged two to seven. We offer different text formats tailored to specific age groups: for preschoolers (ages 2-4), the text is shorter and simpler, making it perfect for early developmental stages. For early readers (ages 5-7), the text is more detailed and complex, suited to their advancing reading skills."
    },
    {
      id: 5,
      question: "How long does it take to receive the e-book?",
      answer:
      "After the customer submits their input, it usually takes about 2 minutes to generate an e-book with our Essential version. With our Enhanced version, the timing depends on the extent of editing the user wants. More extensive customizations and image revisions can take a few additional minutes, but this allows for greater personalization and higher quality results."    },
    {
      id: 6,
      question: "Can I create a free book before purchasing?",
      answer:
        "Absolutely! As a special thank you for supporting our woman-owned small business, we offer your first personalized e-book for free upon sign up. After your first book, we keep our e-book prices around the cost of a cup of coffee to ensure affordability. Additionally, we regularly add new stories to our free gallery for you to enjoy. Your support helps us thrive and allows us to continue sharing this unique experience with families. Thank you for being part of our journey!"
    },
    {
      id: 7,
      question: "Who owns the rights to the books I create?",
      answer:
        "You retain full rights to the books you create on our platform. The content you generate is yours to enjoy, share with family, or use for personal purposes. You're free to print personal copies, share digital versions with family members, and read them privately. While MyTaleMaker maintains the underlying technology and platform rights, the specific stories you create with your input belong to you. We'll never reuse or share your personalized content without your explicit permission."
   //        "You retain full personal usage rights to the books you create on our platform. The content you generate is yours to enjoy, share with family, or use for personal purposes. You're free to print personal copies, share digital versions with family members, and read them privately. While MyTaleMaker maintains the underlying technology and platform rights, the specific stories you create with your input belong to you for personal, non-commercial use. We'll never reuse or share your personalized content without your explicit permission."

      }
  ];

  return (
    <div className="bg-indigo-50 py-12 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Frequently asked questions
          </h2>
          <p className="mt-6 text-base leading-7 text-gray-700">
            Have a different question and can't find the answer you're looking for? Reach out to our support team by{' '}
            <a href="mailto:contact@mytalemaker.com" className="font-semibold text-indigo-600 hover:text-indigo-500">
              sending us an email
            </a>{' '}
            and we'll get back to you as soon as we can.
          </p>
        </div>
        
        <div className="mt-8 max-w-4xl mx-auto">
          <dl className="space-y-4">
            {faqs.map((faq) => (
              <div 
                key={faq.id} 
                className={`bg-white px-6 py-5 rounded-lg shadow-sm border ${openFaq === faq.id ? 'border-indigo-200' : 'border-gray-100'} cursor-pointer transition-all duration-200`}
                onClick={() => toggleFaq(faq.id)}
              >
                <dt className="flex justify-between items-center text-lg font-semibold text-gray-900">
                  {faq.question}
                  <span className="ml-6 flex-shrink-0">
                    {openFaq === faq.id ? (
                      <ChevronUpIcon className="h-5 w-5 text-indigo-600" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5 text-indigo-600" />
                    )}
                  </span>
                </dt>
                {openFaq === faq.id && (
                  <dd className="mt-4 text-base leading-7 text-gray-600 pr-6">
                    {faq.answer}
                  </dd>
                )}
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
