import { UseUserAuth } from "./context/UseAuthContext";
import PageNav from "./components/PageNav";
import { useState, useEffect } from "react";
import { CurrencyDollarIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom'; 

export default function Account() {
  const { user } = UseUserAuth();
  const [isLoading, setIsLoading] = useState(false);
  console.log("user-acc",user)
  // At the top of your Account component
  console.log("Current user state:", {
  user: user ? user.email : "no user",
  emailVerified: user.emailVerified ,
  
});
  const [credits, setCredits] = useState(0);
  const emailVerified = user.emailVerified;
  const navigate = useNavigate();
  //console.log("acc-path", `${process.env.REACT_APP_BACKEND}/user/getNumCredits`)
  
   // Function to update email verification status on the backend
   const updateEmailVerified = async () => {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/usersEmailVerificationInfo`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
          body: JSON.stringify({ emailVerified : emailVerified }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update email verification status");
      } else {
        console.log("Email verification status updated successfully");
        localStorage.setItem('emailVerifiedStatus', 'true');  // Store in localStorage
      }
    } catch (error) {
      console.error("Error updating email verification status:", error);
    }
  };

  // Function to fetch credit information from the backend
  const fetchCreditInfo = async () => {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/getNumCredits`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
        }
      );
      if (response.ok) {
        const userCredits = await response.json();
        setCredits(userCredits);
      } else {
        console.error("Failed to fetch credit information");
      }
    } catch (error) {
      console.error("Error fetching credit information:", error);
    }
  };

  const checkAndFetchCredits = async () => {
    if (user) {
      const hasUpdatedEmailVerified = localStorage.getItem('emailVerifiedStatus') === 'true';
      console.log("hasUpdatedEmailVerified - acc", hasUpdatedEmailVerified);
      if (emailVerified && !hasUpdatedEmailVerified) {
        console.log("updating acc")
        await updateEmailVerified();
      }
      await fetchCreditInfo();
      if (credits > 0) {
        navigate('/create');
      }
    }
  };

  useEffect(() => {
    const justVerified = new URLSearchParams(window.location.search).get('verified') === 'true';
  
  if (justVerified && user) {
    // Show a loading message
    setIsLoading(true);
    
    // Wait a bit for Firebase to update its systems
    setTimeout(async () => {
      try {
        // Force refresh the token
        await user.getIdToken(true);
        // Now check verification status
        if (user.emailVerified) {
          await updateEmailVerified();
        }
        await fetchCreditInfo();
      } catch (err) {
        console.error("Error after verification:", err);
      } finally {
        setIsLoading(false);
      }
    }, 5000); // Wait 3 seconds
  } else {
    // Normal flow
    checkAndFetchCredits();
  }
}, [user, emailVerified]);

  // Conditional rendering based on email verification status
// Keep your existing condition and structure, just update the content inside the span
if (!emailVerified) {
  return (
    <div>
      <PageNav className="absolute inset-x-0 top-0 z-50" />
      <Heading />
      <div className="flex justify-center bg-white px-6 py-10 lg:px-8">
        <div
          role="list"
          className="mt-8 max-w-2xl space-y-8 text-gray-800 text-justify"
        >
          <div className="flex gap-x-3">
            <ExclamationCircleIcon
              className="mt-1 h-5 w-10 flex-none text-indigo-600"
              aria-hidden="true"
            />
            <div className="space-y-4">
              {/* Main message */}
              <p>
                Please verify your email address. An email verification link has been sent to your email from 
                <span className="font-semibold"> noreply@mytalemaker.com</span>
              </p>
              
              {/* Additional helpful information boxes */}
              <div className="bg-gray-50 p-3 rounded-md border-l-4 border-indigo-600">
                <p><strong className="font-semibold">Can't find the email? </strong> 
                Please check your spam or junk folder.</p>
              </div>
              
              <div className="bg-gray-50 p-3 rounded-md border-l-4 border-indigo-600">
                <p><strong className="font-semibold">Using a mobile phone? </strong> 
                You may need to log in again after verification.</p>
              </div>
              
              <div className="bg-gray-50 p-3 rounded-md border-l-4 border-indigo-600">
                <p><strong className="font-semibold">Using multiple browsers? </strong> 
                Each browser requires a separate login.</p>
              </div>
              
              <p className="text-center text-gray-600">
                If you have already verified your email, please log out and log in again.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

  return (
    <>
      <PageNav className="absolute inset-x-0 top-0 z-50" />
      <Heading />
      {isLoading ? (
  <div className="flex justify-center bg-white px-6 py-10 lg:px-8">
    <div className="mt-8 max-w-2xl space-y-8 text-gray-800 text-center">
      <p className="font-semibold">Email verification successful!</p>
      <p>Please wait a moment while we update your account...</p>
    </div>
  </div>
):(
  <>
      {credits > 0 ? (
        <div className=" flex justify-center bg-white px-6 py-10 lg:px-8">
          <ul
            role="list"
            className="mt-8 max-w-2xl space-y-8 text-gray-600 text-justify"
          >
            <li className="flex gap-x-3">
              <CurrencyDollarIcon
                className="mt-1 h-5 w-10 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  Current Credit Status:{" "}
                </strong>
                Your available credit balance is {credits || 0} credits. Please proceed by clicking on the
                <NavLink
                to="/create"
                //className="bg-indigo-600 text-white px-4 py-2 rounded font-semibold hover:bg-indigo-700 hover:text-white"
                className="font-semibold text-gray-700 hover:text-gray-500 underline"
              > create page </NavLink> to create your custom book.
              </span>
            </li>
          </ul>
        </div>
      ) : (
        <div className=" flex justify-center bg-white px-6 py-10 lg:px-8">
          <ul
            role="list"
            className="mt-8 max-w-2xl space-y-8 text-gray-600 text-justify"
          >
            <li className="flex gap-x-3">
              <CurrencyDollarIcon
                className="mt-1 h-5 w-10 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  Current Credit Status:{" "}
                </strong>
                {" "}Your available credit balance is {credits || 0} credit. Please
                proceed by clicking on the{" "}
                <NavLink
                  to={`/?section=pricing`}
                  className="font-semibold text-gray-700 hover:text-gray-500 underline"
                >
                  pricing page
                </NavLink>{" "} to buy credits.
              </span>
            </li>
          </ul>
        </div>
      )}
   
    </>
  )}
  </>
  );
}
function Heading() {
  const { user } = UseUserAuth();
  const [userName, setUserName] = useState("");
 // console.log("create user", user);

 const fetchUserName = async () => {
  try {
    const idToken = await user.getIdToken();
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/user/getUserName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user name");
    }

    const data = await response.json();
    setUserName(data);
  } catch (error) {
    console.error("Error fetching user name:", error);
  }
};

useEffect(() => {
  if (user) {
    fetchUserName();
  }
}, [user]);

return (
  <div className="flex justify-center text-lg font-semibold p-4 box mt-3 text-center text-indigo-600">
    Welcome <br />
    {userName || (user ? (user.displayName ? user.displayName : user.email) : "Guest")}
  </div>
);
}
