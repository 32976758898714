import PageNav from "./components/PageNav";
import { NavLink } from "react-router-dom";
import { UseUserAuth } from './context/UseAuthContext';
import React, {  useEffect, useState } from "react";
import Pricing from './Pricing';
import Contact from "./Contact";
//import CreationInstructions from './CreationInstructions';
import Banner from './Banner'; 
import BannerFreebie from "./BannerFreebie";
import { Helmet } from 'react-helmet';


export default function AdLandingPage() {
  
   //const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
   const pricingRef = React.createRef();
   const [showBanner, setShowBanner] = useState(false);
   const [showFreebie, setShowFreebie] = useState(false);
   const structuredData =  {
     "@context": "https://schema.org",
     "@type": "WebSite",
     "name": "MyTaleMaker",
     "url": "https://www.mytalemaker.com",
     "description": "Create personalized stories for children in minutes using AI. Tailored to your child's imagination with beautiful illustrations."
   }
   useEffect(() => {
     async function fetchData(){
     try {
 
       const response = await fetch(
         `${process.env.REACT_APP_BACKEND}/public/getCountry`,
         {
           method: "GET",
           headers: {
             "Content-Type": "application/json"
           },
         }
       );
 
       const data = await response.json();
       if (data.status === 403){
         setShowBanner(true);
         setShowFreebie(false);
       //  alert('We are sorry. Currently, the beta launch is not available in your country. We hope to see you very soon!')
       }
       else{ if(data.status === 200) {
         setShowBanner(false);
         setShowFreebie(true);
       }
       }
     } catch (error) {
       console.error("Error fetching country information:", error);
     }
   };
       // Call fetchData to fetch country information
   fetchData();
     // Handle URL parameters for scrolling
     const urlParams = new URLSearchParams(window.location.search);
     const section = urlParams.get("section");
 
     if (section === "pricing") {
       pricingRef.current.scrollIntoView({ behavior: "smooth" });
     } else {
       // Optional: Handle the case where no section parameter is provided
       // (e.g., do nothing, scroll to the top, etc.)
       // window.scrollTo(0, 0); // Scroll to the top of the page
     }
   }, []);
   return (
     <div className="bg-white">
       <Helmet>
         <title>MyTaleMaker</title>
         <meta property="og:site_name" content="MyTaleMaker" />
         <meta name="description" content="Create personalized stories for children in minutes using AI. Tailored to your child's imagination with beautiful illustrations." />
         <meta property="og:title" content="MyTaleMaker" />
         <meta property="og:description" content="Create personalized stories for children in minutes using AI. Tailored to your child's imagination with beautiful illustrations." />
         <meta name="application-name" content="MyTaleMaker" />
         <meta property="og:url" content="https://www.mytalemaker.com" />
         <meta property="og:image" content="https://static.mytalemaker.com/logo-icon.png" />
         <link rel="canonical" href="https://www.mytalemaker.com" />
         <script type="application/ld+json">
           {JSON.stringify(structuredData)}
         </script>
       </Helmet>
       <PageNav classname= "absolute inset-x-0 top-0 z-50"/>  
       {showBanner && <Banner />} {/*onConfirm={() => setShowBanner(false)} */}
       {showFreebie && <BannerFreebie/>} 
       <Display />    
      {/* <CreationInstructions/> */}
       <div ref={pricingRef}> 
         <Pricing />
       </div> 
       <Contact/>
     </div>
   );
 }
 

 function Display() {
    const { user } = UseUserAuth();
  
    return (
      <main>
        <div className="bg-white">
          <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-4">
            <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
                <div className="lg:col-span-1">
                  {/* Banner */}
                  <div className="inline-flex items-center bg-indigo-600 text-white px-4 py-2 rounded-full text-sm font-medium">
                    ⭐ Create Personalized Children's Stories with AI
                  </div> 
  
                  <h1 className="mt-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
  Write Your Child Into Their Own Adventure
</h1>
{/*<p className="mt-4 text-lg text-gray-600">
Share magical moments together with AI-powered personalized stories, ready to read in as little as 2 minutes. Perfect for fostering a love of reading, supporting social-emotional learning, building confidence, and crafting bedtime stories. Entertain, inspire, and create lasting family memories!                  </p> */}
                  <p className="mt-4 text-lg text-gray-600">
                  Engage your child with beautifully illustrated custom stories that spark creativity, promote social skills, and foster a love for reading. Entertain, inspire, and create lasting family memories!
                  </p>

                  {/* Trust Signals */}
                  <div className="mt-6 space-y-3">
                    <div className="flex items-center gap-x-2">
                      <span className="text-indigo-600">✓</span>
                      <span>First personalized story completely free - No credit card needed</span>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <span className="text-indigo-600">✓</span>
                      <span>Create custom stories in as little as 2 minutes</span>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <span className="text-indigo-600">✓</span>
                      <span>Created with love by a mom for ages 2-7</span>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <span className="text-indigo-600">✓</span>
                      <span>10 beautiful AI illustrations per story</span>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <span className="text-indigo-600">✓</span>
                      <span>Download as PDF or read online instantly</span>
                    </div>
                  </div>
  
                  {/* CTAs */}
                  <div className="mt-8 flex items-center gap-x-4">
                    <NavLink
                      to={user ? '/create' : "/login"}
                      className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Start Your Free Story
                    </NavLink>
                    <NavLink 
                      to="/gallery" 
                      className="text-sm font-semibold leading-6 text-gray-900"
                    >
                      See Examples <span aria-hidden="true">→</span>
                    </NavLink>
                  </div>
                </div>
  
                                {/* Happy Family Image */}
                <div className="mt-8 lg:mt-0">
                <img
                    //src="https://static.mytalemaker.com/family_mytalemaker.jpg" // You'll need to update this path
                    src="https://static.mytalemaker.com/family_mytalemaker2.webp"
                    alt="Happy family creating a personalized story together on MyTaleMaker"
                    className="w-full rounded-2xl object-cover shadow-xl"
                    width="1024"
                    height="1024"
                    loading="lazy"
                    fetchpriority="high"
                />
                </div>
              </div>
 
             
            </div>
            <div className="absolute inset-x-0 bottom-0 -z-10 h-16 bg-gradient-to-t from-white sm:h-24" />
          </div>
        </div>
      </main>
    );
  }


